import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import Balance from './Balance'
import { Appstate } from '../App'
import { DownloadOutlined, InboxOutlined, LeaderboardOutlined, OutboundOutlined, PoolOutlined, UpgradeOutlined } from '@mui/icons-material'
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { TailSpin } from 'react-loader-spinner'
import { message } from 'antd'
import { ethers } from 'ethers'

const Admin = () => {
    const useAppState = useContext(Appstate);
    const [left, setLeft] = useState(0);
    const [rank, setRank] = useState(0);
    const [APDist, setAPDist] = useState(0);
    const [APDeduct, setAPDeduct] = useState(0);
    const [autoUpgrade, setAutoUpgrade] = useState(0)
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [loading6, setLoading6] = useState(false);
    const [loading7, setLoading7] = useState(false);
    const [loading8, setLoading8] = useState(false);
    const [loading9, setLoading9] = useState(false);
    const [amount, setAmount] = useState("");
    const [amount2, setAmount2] = useState("");
    const [bal, setBal] = useState(0);
    const [teamDiff, setTeamDiff] = useState("");
    const [MainLayer, setMainLayer] = useState("");

    const [newAddr, setNewAddr] = useState("");
    const [newRef, setNewRef] = useState("");

    const [buyId, setBuyId] = useState("");
    
    const [rankType, setRankType] = useState(0);
    const [distSlot, setDistSlot] = useState(1);
    const [dynamicLayers, setDynamicLayers] = useState("");

    useEffect(() => {
        async function getData() {
          let provider = new ethers.providers.Web3Provider(window.ethereum);
          let _bal = await provider.getBalance(useAppState.hotWalletAddr);
          // let _bal = await useAppState.tokenContract.balanceOf(useAppState.hotWalletAddr);

          setBal(ethers.utils.formatEther(_bal));
          // setBal(useAppState.convert(_bal));

          let rankTotal = 0;
          for(let i=0; i<4; i++) {
            let _rank = await useAppState.contract.rankPool(i);
            rankTotal += Number(useAppState.convert(_rank));
          }
          setRank(rankTotal); 

          let apDistTotal = 0;
          for(let i=0; i<10; i++) {
            let _rank = await useAppState.contract.APDist(i);
            apDistTotal += Number(useAppState.convert(_rank));
          }
          setAPDist(apDistTotal); 

          let apDeductTotal = 0;
          for(let i=0; i<10; i++) {
            let _rank = await useAppState.contract.APDeduct(i);
            apDeductTotal += Number(useAppState.convert(_rank));
          }
          setAPDeduct(apDeductTotal); 

          let _au = await useAppState.contract.AURemain();
          setAutoUpgrade(useAppState.convert(_au));
        }
        getData();
    },[useAppState.walletAddress, useAppState.change])

    const distribute = async () => {
      setLoading(true);
      try {
        let tx = await useAppState.contract.distributeRankPool();
        await tx.wait();
        message.success("Sucessfully Distributed");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading(false);
    }

    const withdraw = async () => {
      setLoading2(true);
      try {
        let tx = await useAppState.contract.claim(ethers.utils.parseEther(amount.toString()));
        // let tx = await useAppState.contract.claim(ethers.utils.parseUnits(amount.toString(), "mwei"));
        await tx.wait();
        message.success("Sucessfully Withdrawn");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading2(false);
    }

    const addSlots = async () => {
      setLoading3(true);
      try {
        let tx = await useAppState.contract.addSlots(0, 0, 0, 0, 0, "0x74F56a7560eF0C72Cf6D677e3f5f51C2D579fF15");
        await tx.wait();
        message.success("Sucessfully added slots");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading3(false);
    }

    const changeTeamDiff = async () => {
      setLoading4(true);
      try {
        let tx = await useAppState.contract.addSlots(1, teamDiff, 0, 0, 0, "0x74F56a7560eF0C72Cf6D677e3f5f51C2D579fF15");
        await tx.wait();
        message.success("Team Difference changed");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading4(false);
    }

    const changeRankPoolBal = async () => {
      setLoading5(true);
      try {
        let tx = await useAppState.contract.addSlots(2, 0, distSlot, rankType, ethers.utils.parseUnits(amount2.toString(), "mwei"), "0x74F56a7560eF0C72Cf6D677e3f5f51C2D579fF15");
        await tx.wait();
        message.success("Pool Updated");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading5(false);
    }

    const changeDynamicLayerUpdate = async () => {
      setLoading6(true);
      try {
        let tx = await useAppState.contract.addSlots(3, dynamicLayers, 0, 0, 0, "0x74F56a7560eF0C72Cf6D677e3f5f51C2D579fF15");
        await tx.wait();
        message.success("Layers Updated");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading6(false);
    }

    const changeMainUplineLayer = async () => {
      setLoading7(true);
      try {
        let tx = await useAppState.contract.addSlots(4, MainLayer, 0, 0, 0, "0x74F56a7560eF0C72Cf6D677e3f5f51C2D579fF15");
        await tx.wait();
        message.success("Levels Updated");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading7(false);
    }

    const registerUser = async () => {
      setLoading8(true);
      try {
        let tx = await useAppState.contract.register(newAddr, newRef);
        await tx.wait();
        message.success("Registered");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading8(false);
    }

    const buyUser = async () => {
      setLoading9(true);
      try {
        let id = await useAppState.contract.userInfo(buyId);
        window.alert(Number(id.id));
        // let tx = await useAppState.contract.buySlot(buyId);
        // await tx.wait();
        // message.success("Slot Purchased");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading9(false);
    }

  return (
    <div className="flex justify-center p-4">
    <div className="md:w-4/5 w-full font-semibold">
      <Header />
      <Balance />
        <div className="mt-6 w-full bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
          <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
            <span className='flex items-center'>
              <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
                <LeaderboardIcon />
              </span> 
              Ranks
            </span>
            <span>${rank}</span>
          </div>
          <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
            <span className='flex items-center'>
              <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
                <OutboundOutlined />
              </span> 
              Autopool Distributed
            </span>
            <span>${APDist}</span>
          </div>
          <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
            <span className='flex items-center'>
              <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
                <DownloadOutlined />
              </span> 
              Autopool Deducted
            </span>
            <span>${APDeduct}</span>
          </div>
          <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
            <span className='flex items-center'>
              <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
                <UpgradeOutlined />
              </span> 
              Autoupgrade
            </span>
            <span>${autoUpgrade}</span>
          </div>
          
          <button onClick={distribute} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Distribute Pool"}</button>
          
          <h3 className='mt-6 font-bold text-lg'>Hot Wallet Balance: {bal} CORE</h3>
          <input className='mt-2 w-full rounded-sm outline-none text-gray-800 p-2' value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Enter Amount' />
          <button onClick={withdraw} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading2 ? <TailSpin height={22} color='white' /> : "Withdraw"}</button>

          <button onClick={addSlots} className='bg-lime-500 mb-2 mt-6 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading3 ? <TailSpin height={22} color='white' /> : "Increase Slots"}</button>
          <input className='mt-2 w-full rounded-sm outline-none text-gray-800 p-2' value={teamDiff} onChange={(e) => setTeamDiff(e.target.value)} placeholder='Enter Team Difference' />
          <button onClick={changeTeamDiff} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading4 ? <TailSpin height={22} color='white' /> : "Update Team Difference"}</button>

          <div className='mt-6 flex items-center'>
            <p onClick={() => setDistSlot(1)} className={`${distSlot == 1 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer p-2 rounded-sm`}>B1</p>
            <p onClick={() => setDistSlot(2)} className={`${distSlot == 2 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer ml-6 p-2 rounded-sm`}>B2</p>
            <p onClick={() => setDistSlot(3)} className={`${distSlot == 3 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer ml-6 p-2 rounded-sm`}>B3</p>
            <p onClick={() => setDistSlot(4)} className={`${distSlot == 4 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer ml-6 p-2 rounded-sm`}>B4</p>
          </div>
          <div className='mt-2 flex items-center'>
            <p onClick={() => setRankType(0)} className={`${rankType == 0 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer p-2 rounded-sm`}>Decrease</p>
            <p onClick={() => setRankType(1)} className={`${rankType == 1 ? "bg-blue-500" : "bg-[#8080821a]"} cursor-pointer ml-6 p-2 rounded-sm`}>Increase</p>
          </div>
          <input className='mt-2 w-full rounded-sm outline-none text-gray-800 p-2' value={amount2} onChange={(e) => setAmount2(e.target.value)} placeholder='Enter Amount' />
          <button onClick={changeRankPoolBal} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading5 ? <TailSpin height={22} color='white' /> : (rankType == 0 ? "Decrease" : "Increase")}</button>

          <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={dynamicLayers} onChange={(e) => setDynamicLayers(e.target.value)} placeholder='Enter Layers' />
          <button onClick={changeDynamicLayerUpdate} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading6 ? <TailSpin height={22} color='white' /> : "Update Layers"}</button>

          <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={MainLayer} onChange={(e) => setMainLayer(e.target.value)} placeholder='Enter Levels' />
          <button onClick={changeMainUplineLayer} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading7 ? <TailSpin height={22} color='white' /> : "Update Main Levels"}</button>

          <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={newAddr} onChange={(e) => setNewAddr(e.target.value)} placeholder='Enter New Address' />
          <input className='mt-2 w-full rounded-sm outline-none text-gray-800 p-2' value={newRef} onChange={(e) => setNewRef(e.target.value)} placeholder='Enter Ref ID' />
          <button onClick={registerUser} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading8 ? <TailSpin height={22} color='white' /> : "Register"}</button>

          {/* <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={buyId} onChange={(e) => setBuyId(e.target.value)} placeholder='Enter Address' />
          <button onClick={buyUser} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading9 ? <TailSpin height={22} color='white' /> : "Buy Slot"}</button> */}

          <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={buyId} onChange={(e) => setBuyId(e.target.value)} placeholder='Enter Address' />
          <button onClick={buyUser} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading9 ? <TailSpin height={22} color='white' /> : "GET ID"}</button>
      </div>
    </div>
    </div>
  )
}

export default Admin