import { Routes, Route, Navigate, useLocation, Router, useNavigate } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import Dashboard from './Components/Dashboard'
import Register from './Components/Register'
import Homepage from './Components/Homepage'
import Teams from './Components/Teams'
import Wallet from './Components/Wallet'
import Blockocean from '../src/artifacts/contracts/Blockocean.sol/Blockocean.json'
import Admin from "./Components/Admin";

const Appstate = createContext();

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

function App() {
  const {ethereum} = window;
  const Navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  
  const convert = (amount) => {
    return Number(ethers.utils.formatUnits(amount.toString(), "mwei"));
  }
  
  const usdt = "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1";
  // const usdt = "0x7b8bD851d74Ca0eA1eC37AB8aE6fCF3d81cd3108";
  const mainAddr = "0xdD955d3ccddA187309778C57A4d15a018f09c733";
  const defaultRefer = "12956";
  const hotWalletAddr = "0xD4C9B55b694d80dC64c8176B198b4CEE12B9b153";
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, Blockocean.abi, signer);
  const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      // setWalletAddress("0x650c372Cf245C5771D0441E942eBD3f399fa5b7C");
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  function getUTCTime(ms) {
    const now = new Date(ms);

    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1; // Months are zero-indexed
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    const utcTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} UTC`;
    return utcTime;
  }

  return (
    <Appstate.Provider value={{getUTCTime, usdt, mainAddr, hotWalletAddr, contract, defaultRefer, tokenContract, walletAddress,setWalletAddress, setChange, change, convert}}>
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/maple" element={<Admin />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate}
