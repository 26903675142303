import { Link, useLocation } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import WalletIcon from '@mui/icons-material/Wallet';
import { AdminPanelSettings } from "@mui/icons-material";

const Header = () => {
  const location = useLocation();

  return (
    <>
      <div className="fixed top-0 left-0 flex justify-between p-3 z-[200] w-full blue_gradient">
        <Link to={'/'} className="flex items-center"><img src="logo192.png" className="h-8 ml-2 mr-2" /><span className="font-bold text-lg">BLOCK<span className="text-sky-500">OCEAN</span></span></Link>
        <Link to={'/'} className="py-2 px-4 rounded-sm bg-red-500 hover:bg-red-600 text-sm font-semibold">Logout</Link>
      </div>
      <div className='w-full mt-16 z-[100] overflow-x-auto rounded-full flex justify-between shadow-xl bg-[#406aff] font-medium'>
        <Link className="w-full" to={'/dashboard'}><p className={`${location.pathname == '/dashboard' ? 'bg-[#ffffff1a] ' : ''} w-full whitespace-nowrap md:py-3 py-4 px-2 text-center transition-colors duration-300 hover:bg-[#ffffff1a] ease-in rounded-full`}><DashboardIcon fontSize="small" /> Dashboard</p></Link>
        <Link className="w-full" to={'/teams'}><p className={`${location.pathname == '/teams' ? 'bg-[#ffffff1a] ' : ''} w-full whitespace-nowrap md:py-3 py-4 px-2 text-center transition-colors duration-300 hover:bg-[#ffffff1a] ease-in rounded-full`}><GroupsIcon fontSize="small" /> Teams</p></Link>
        <Link className="w-full" to={'/wallet'}><p className={`${location.pathname == '/wallet' ? 'bg-[#ffffff1a] ' : ''} w-full whitespace-nowrap md:py-3 py-4 px-2 text-center transition-colors duration-300 hover:bg-[#ffffff1a] ease-in rounded-full`}><WalletIcon fontSize="small" /> Wallet</p></Link>
        {/* <Link className="w-full" to={'/manage'}><p className={`${location.pathname == '/manage' ? 'bg-[#ffffff1a] ' : ''} w-full whitespace-nowrap md:py-3 py-4 px-2 text-center transition-colors duration-300 hover:bg-[#ffffff1a] ease-in rounded-full`}><AdminPanelSettings fontSize="small" /> Admin</p></Link> */}
      </div>
    </>
  )
}

export default Header