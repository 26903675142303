import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import Downline from './Downline'
import { Appstate } from '../App'
import { Modal } from 'antd'

const Teams = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    directTeam: 0,
    totalTeam: 0,
    passupTeam: 0,
    totalBusiness: 0,
    directBusiness: 0,
    sl1: 0,
    sl2: 0,
    sl3: 0,
    sl4: 0,
    slb1: 0,
    slb2: 0,
    slb3: 0,
    slb4: 0,
    slot: 0
  })

  const [B1, setB1] = useState({
    direct: [0, 0],
    b1: [0, 0],
    b2: [0, 0],
    b3: [0, 0],
    b4: [0, 0],
    slot: [0, 0],
    team: [0, 0]
  })

  const [rankedTeam, setRankedTeam] = useState([[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]])
  const [directRequired, setDirectRequired] = useState([4, 4, 4, 4]);
  const [topLegs, setTopLegs] = useState([])
  const [slots, setSlots] = useState([4, 5, 6, 7]);
  const [show, setShow] = useState(false);
  const [directUsers, setDirectUsers] = useState([{
    sno: 0,
    address: "",
    start: 0,
    id: 0,
    slots: 0,
    directTeam: 0,
    totalTeam: 0,
    directBusiness: 0,
    totalBusiness: 0,
    totalDeposit: 0,
  }])

  useEffect(() => {
    async function getData() {
      // let _directUser = await useAppState.contract.teamUsers(useAppState.walletAddress, 1, 0);
      // window.alert(_directUser);
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _TeamInfo = await useAppState.contract.getDynamicSerializedLegs(useAppState.walletAddress);
      setData({
        directTeam: Number(_user.directTeam),
        totalTeam: Number(_TeamInfo[5]),
        passupTeam: Number(_user.passUpTeam),
        totalBusiness: useAppState.convert(_TeamInfo[4]),
        directBusiness: useAppState.convert(_user.directBusiness),
        sl1: Number(_TeamInfo[3][0]),
        sl2: Number(_TeamInfo[3][1]),
        sl3: Number(_TeamInfo[3][2]),
        sl4: Number(_TeamInfo[3][3]),
        slb1: useAppState.convert(_TeamInfo[2][0]),
        slb2: useAppState.convert(_TeamInfo[2][1]),
        slb3: useAppState.convert(_TeamInfo[2][2]),
        slb4: useAppState.convert(_TeamInfo[2][3]),
        slot: Number(_user.slots)
      })
      setTopLegs(_TeamInfo[1]);
      // let _b1 = await useAppState.contract.getUserB1Progress(useAppState.walletAddress);
      setB1({
        direct: [Number(_user.directTeam), 4],
        b1: [useAppState.convert(_TeamInfo[2][0]), 5000],
        b2: [useAppState.convert(_TeamInfo[2][1]), 5000],
        b3: [useAppState.convert(_TeamInfo[2][2]), 5000],
        b4: [useAppState.convert(_TeamInfo[2][3]), 5000],
        slot: [Number(_user.slots), 4],
        team: [Number(_TeamInfo[5]), 200]
      })

      let _rankedTeam = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]]
      for(let i=0; i<4; i++) {
        for(let j=0; j<4; j++) {
          _rankedTeam[i][j] = Number(_TeamInfo[0][i][j])
        }
      }
      setRankedTeam(_rankedTeam);
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  useEffect(() => {
    async function getData() {
      setDirectUsers([]);
      let user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let directTeam = Number(user.directTeam);
      for(let i=0; i<directTeam; i++) {
        let _user = await useAppState.contract.teamUsers(useAppState.walletAddress, 1, i);
        let _curUserInfo = await useAppState.contract.userInfo(_user);
        setDirectUsers((prev) => [...prev, {
          sno: i+1,
          address: _user,
          start: Number(_curUserInfo.start) * 1000,
          id: Number(_curUserInfo.id),
          slots: Number(_curUserInfo.slots),
          directTeam: Number(_curUserInfo.directTeam),
          totalTeam: Number(_curUserInfo.totalTeam),
          directBusiness: useAppState.convert(_curUserInfo.directBusiness),
          totalBusiness: useAppState.convert(_curUserInfo.totalBusiness),
          totalDeposit: useAppState.convert(_curUserInfo.totalDeposit),
        }])
      }
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  
  return (
    <div className="flex justify-center p-4">
      <div className="md:w-4/5 w-full">
        <Header />

        {/* Direct Teams */}
        <Modal
          open={show}
          footer={null}
          onCancel={() => setShow(false)}
          title="Direct Teams"
        >
          <div className="">
            {data.directTeam > 0 ? (
              <div className="flex justify-center">
                <div className="w-full">
                  <div className="overflow-auto flex justify-between w-full mt-2 p-2 rounded-sm">
                    <div className="whitespace-nowrap ml-0 md:ml-0">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        SNo.
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {e.sno}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        ID
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {e.id}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        Address
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {e.address.slice(0,8)}...{e.address.slice(38)}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        Slots Purchased
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {e.slots}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        Direct Team
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {e.directTeam}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        Total Business
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            ${e.totalBusiness}
                          </p>
                        );
                      })}
                    </div>

                    <div className="whitespace-nowrap ml-4">
                      <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                        Joining Time
                      </p>
                      {directUsers.map((e, i) => {
                        return (
                          <p
                            key={i}
                            className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-gray-800 bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                          >
                            {useAppState.getUTCTime(e.start)}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>

        <div className="flex justify-between">
          <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
            <div className="font-bold">
              <p className="">Total Teams</p>
              <p className="text-xl">{data.totalTeam}</p>
            </div>
            <img src="teams.png" className="h-20" />
          </div>

          <div
            onClick={() => setShow(!show)}
            className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile"
          >
            <div className="font-bold">
              <p className="">Direct Teams</p>
              <p className="text-xl">{data.directTeam}</p>
            </div>
            <img src="direct.png" className="h-20" />
          </div>
        </div>

        {/* <div className='flex justify-between'>
        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
          <div className='font-bold'>
            <p className=''>Passup Teams</p>
            <p className='text-xl'>{data.passupTeam}</p>
          </div>
          <img src='passup.png' className='h-16' />
        </div>

        <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
          <div className='font-bold'>
            <p className=''>Strong Leg Business</p>
            <p className='text-xl'>${data.slb1}</p>
          </div>
          <img src='strongleg.png' className='h-20' />
        </div>
      </div> */}

        <div className="flex justify-between">
          <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
            <div className="font-bold">
              <p className="">Total Business</p>
              <p className="text-xl">${data.totalBusiness}</p>
            </div>
            <img src="matictoken.png" className="h-14" />
          </div>

          <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
            <div className="font-bold">
              <p className="">Direct Business</p>
              <p className="text-xl">${data.directBusiness}</p>
            </div>
            <img src="directbusiness.png" className="h-16" />
          </div>
        </div>

        {/* Ranks Team */}
        <div className="flex justify-between overflow-x-auto">
          <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] py-2 rounded-2xl shadow-lg wallet">
            <div className="flex flex-col items-center w-full min-w-[40px] px-4">
              <p className="text-sm font-medium whitespace-nowrap">
                Strong Leg 1st
              </p>
              {topLegs.length > 0 ? (
                <p className="text-sm font-medium whitespace-nowrap">
                  ({topLegs[0].slice(0, 8)}...{topLegs[0].slice(38)})
                </p>
              ) : null}
              <p className="font-bold text-lg">
                {data.sl1} - ${data.slb1}
              </p>
            </div>
          </div>

          <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] py-2 rounded-2xl shadow-lg wallet">
            <div className="flex flex-col items-center w-full min-w-[40px] px-4">
              <p className="text-sm font-medium whitespace-nowrap">
                Strong Leg 2nd
              </p>
              {topLegs.length > 0 ? (
                <p className="text-sm font-medium whitespace-nowrap">
                  ({topLegs[1].slice(0, 8)}...{topLegs[1].slice(38)})
                </p>
              ) : null}
              <p className="font-bold text-lg">
                {data.sl2} - ${data.slb2}
              </p>
            </div>
          </div>

          <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] py-2 rounded-2xl shadow-lg wallet">
            <div className="flex flex-col items-center w-full min-w-[40px] px-4">
              <p className="text-sm font-medium whitespace-nowrap">
                Strong Leg 3rd
              </p>
              {topLegs.length > 0 ? (
                <p className="text-sm font-medium whitespace-nowrap">
                  ({topLegs[2].slice(0, 8)}...{topLegs[2].slice(38)})
                </p>
              ) : null}
              <p className="font-bold text-lg">
                {data.sl3} - ${data.slb3}
              </p>
            </div>
          </div>

          <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] py-2 rounded-2xl shadow-lg wallet">
            <div className="flex flex-col items-center w-full min-w-[40px] px-4">
              <p className="text-sm font-medium whitespace-nowrap">
                Strong Leg 4th
              </p>
              {topLegs.length > 0 ? (
                <p className="text-sm font-medium whitespace-nowrap">
                  ({topLegs[3].slice(0, 8)}...{topLegs[3].slice(38)})
                </p>
              ) : null}
              <p className="font-bold text-lg">
                {data.sl4} - ${data.slb4}
              </p>
            </div>
          </div>
        </div>

        {/* Ranks */}
        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg autopool">
          <div className="font-bold w-2/3">
            <p className="text-sm">
              Direct Team ({B1.direct[0]}/{B1.direct[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.direct[0] * 100) / B1.direct[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              Total Team ({B1.team[0]}/{B1.team[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.team[0] * 100) / B1.team[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              Slots Purchased ({data.slot}/{slots[0]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(data.slot * 100) / slots[0]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              1st Leg Business (${B1.b1[0]}/${B1.b1[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.b1[0] * 100) / B1.b1[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              2nd Leg Business (${B1.b2[0]}/${B1.b2[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.b2[0] * 100) / B1.b2[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              3rd Leg Business (${B1.b3[0]}/${B1.b3[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.b3[0] * 100) / B1.b3[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              4th Leg Business (${B1.b4[0]}/${B1.b4[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(B1.b4[0] * 100) / B1.b4[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
          </div>
          <img src="b1.png" className="h-28" />
        </div>

        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg autopool">
          <div className="font-bold w-2/3">
            <p className="text-sm">
              Direct Team ({data.directTeam}/{directRequired[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{
                  width: `${(data.directTeam * 100) / directRequired[1]}%`,
                }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              Slots Purchased ({data.slot}/{slots[1]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(data.slot * 100) / slots[1]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B1 1st Leg ({rankedTeam[0][0]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[0][0] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B1 2nd Leg ({rankedTeam[1][0]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[1][0] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B1 3rd Leg ({rankedTeam[2][0]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[2][0] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B1 4th Leg ({rankedTeam[3][0]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[3][0] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
          </div>
          <img src="b2.png" className="h-28" />
        </div>

        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg autopool">
          <div className="font-bold w-2/3">
            <p className="text-sm">
              Direct Team ({data.directTeam}/{directRequired[2]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{
                  width: `${(data.directTeam * 100) / directRequired[2]}%`,
                }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              Slots Purchased ({data.slot}/{slots[2]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(data.slot * 100) / slots[2]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B2 1st Leg ({rankedTeam[0][1]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[0][1] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B2 2nd Leg ({rankedTeam[1][1]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[1][1] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B2 3rd Leg ({rankedTeam[2][1]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[2][1] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B2 4th Leg ({rankedTeam[3][1]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[3][1] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
          </div>
          <img src="b3.png" className="h-28" />
        </div>

        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg autopool">
          <div className="font-bold w-2/3">
            <p className="text-sm">
              Direct Team ({data.directTeam}/{directRequired[3]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{
                  width: `${(data.directTeam * 100) / directRequired[3]}%`,
                }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">
              Slots Purchased ({data.slot}/{slots[3]})
            </p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${(data.slot * 100) / slots[3]}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B3 1st Leg ({rankedTeam[0][2]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[0][2] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B3 2nd Leg ({rankedTeam[1][2]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[1][2] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B3 3rd Leg ({rankedTeam[2][2]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[2][2] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
            <p className="text-sm mt-2">B3 4th Leg ({rankedTeam[3][2]}/1)</p>
            <p className="w-full bg-gray-400 h-1 mt-1 rounded-xl">
              <p
                style={{ width: `${rankedTeam[3][2] * 100}%` }}
                className={`max-w-full bg-green-500 h-full rounded-xl`}
              ></p>
            </p>
          </div>
          <img src="b4.png" className="h-28" />
        </div>

        <Downline />
      </div>
    </div>
  );
}

export default Teams