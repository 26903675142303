import React, { useContext, useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Carousel, message } from 'antd';
import { Modal } from "antd";
import { Appstate } from '../App';


const Homepage = () => {
  const useAppState = useContext(Appstate);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [id, setId] = useState("");
  
  const CorrectSvg = () => (
    <svg
      className="flex-shrink-0 w-5 h-5 text-lime-500 dark:text-lime-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );

  async function getData() {
    setLoad(true);
    let _id = await useAppState.contract.ids(id);
    if(_id == "0x0000000000000000000000000000000000000000") {
      message.error("Invalid Id");
      return;
    } 

    useAppState.setWalletAddress(_id);
    navigate('/dashboard');
    setLoad(false);
  }

  const Login = async () => {
    try {
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress)
      if(Number(_user.start) == 0) {
        message.error("User Not Registered");
      } else {
        navigate('/dashboard');
        message.success("Logged In");
      }
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  }

  return (
    <div className='relative h-screen overflow-x-hidden'>
        {/* Preview Id */}
        <Modal
          open={show}
          footer={null}
          onCancel={() => setShow(false)}
          title="Preview"
        >
          <div className='border-1 w-full bg-gray-600 mt-2 mb-3'></div>
        <div className='flex w-full justify-center items-center'>
          <input onChange={(e) => setId(e.target.value)} value={id} className='bg-gray-200 outline-none text-gray-800 font-semibold p-3 w-2/3' placeholder='Enter Id' />
          <button onClick={getData} className='bg-green-500 w-1/3 ml-3 p-3 font-semibold'>Preview</button>
        </div>

        </Modal>
        <header className='flex bg-[#18191a] z-40 shadow-lg p-5 justify-between items-center w-full h-[70px] sticky top-0'>
          <Link to={'/'} className="flex items-center"><img src="logo192.png" className="h-12 ml-2 mr-2" /><span className="font-bold text-xl">BLOCK<span className="text-sky-500">OCEAN</span></span></Link>
          <div className='flex justify-center items-center'>
            <p onClick={Login} className='bg-lime-500 cursor-pointer py-2 px-6 font-semibold text-sm rounded-md'>Login</p>
            {/* <Link to={'/register'} ><p className='ml-5'>Register</p></Link> */}
          </div>
        </header>    

        <div className='relative flex justify-center h-screen items-center'>
          <img src='background.png' className='absolute top-0 hidden md:block h-full w-full' />
          <img src='mobbg.png' className='absolute block md:hidden -top-12 h-full w-full' />
          <img src='bubble.png' className='absolute h-[400px] rotate mb-[130px] md:mb-[80px]' />
          <img src='blockocean.png' className='absolute h-[160px] mb-[130px] md:mb-[80px] animate-pulse' />
        </div>

        <div className='relative flex justify-start items-center h-[500px] md:h-[700px] -translate-y-16'>
          <img src='planet.webp' className='absolute top-8 -right-24 md:right-0 h-full opacity-70 md:opacity-100 -z-30' />
          <div className='w-full md:w-1/2 md:mt-0 mt-[600px] px-5 text-xl font-semibold'>
          <span className='txtgradient'>Blockocean</span> is an innovative decentralized network platform that leverages the transformative potential of smart contracts to redefine the dynamics of modern marketing. Built on a foundation of <span className='txtgradient'>blockchain technology</span>, <span className='txtgradient'>Blockocean</span> creates a secure and transparent ecosystem. 
          <p onClick={() => setShow(!show)} className='mt-3 bg-blue-500 w-32 flex justify-center items-center rounded-lg p-3 hover:bg-blue-600 text-sm cursor-pointer'>Preview Id</p>
          <div className='mt-16 md:mt-0 flex flex-col md:flex-row justify-between w-full p-5 md:absolute -bottom-0 md:-bottom-14'>
            <div className='flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <EmojiEventsIcon fontSize='large' /> 
              <p>Rewards</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Our decentralized platform offers a variety of rewards for users who contribute to our ecosystem.</p>
            </div>
            <div className='mt-4 md:mt-0 ml-0 md:ml-4 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <VpnLockIcon fontSize='large' /> 
              <p>Secure</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Our platform is built on smart contracts, providing a high level of security for all users.</p>
            </div>
            <div className='mt-4 md:mt-0 ml-0 md:ml-4 mr-0 md:mr-5 flex flex-col items-center md:w-1/3 w-full blackbg rounded-xl p-5'>
              <PersonOffIcon fontSize='large' /> 
              <p>No Admin</p>
              <p className='text-sm text-gray-500 mt-3 text-center'>Smart contracts operate without the need for admin, as they are self-executing.</p>
            </div>
          </div>
          </div>
        </div>

        {/* <div className='flex flex-col mb-8 items-center bggradient2 relative mt-[530px] md:mt-36 p-4 h-[670px] md:h-[400px]'>
          <div className='shadow-lg md:w-3/4 w-full p-4 bg-[#242526] rounded-lg'>
          <Carousel autoplay>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-8 pt-4'>
                <img src='blockchain.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Blockchain</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Blockchain is a decentralized and distributed digital ledger that records and verifies transactions across multiple computers or nodes. It is a foundational technology that underlies cryptocurrencies like Bitcoin, but its applications go beyond digital currencies. In a traditional centralized system, transactions and data are typically stored in a single, trusted entity such as a bank or a government. In contrast, a blockchain network consists of a network of computers, known as nodes, that collectively maintain a shared and synchronized ledger. Each transaction is grouped into a "block" and added to a chain of previous blocks, forming a chronological sequence of records.</p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-10 pt-4'>
                <img src='matictoken.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Core Blockchain</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Core Blockchain is a high-performance, decentralized platform designed to support secure and scalable decentralized applications (dApps). It operates on a Delegated Proof of Stake (DPoS) consensus mechanism, ensuring fast transaction speeds and energy efficiency. Core combines the best features of Ethereum's programmability and Bitcoin’s security, offering compatibility with EVM (Ethereum Virtual Machine) for smart contract development. With a focus on interoperability, Core supports cross-chain functionality, enabling seamless communication between different blockchains. Its robust ecosystem caters to developers, businesses, and users by providing tools for creating dApps, DeFi projects, and more. Core aims to bridge traditional and decentralized technologies efficiently.</p>
              </div>
            </div>
            <div className='w-full'>
              <div className='flex flex-col items-center w-full pb-8 pt-4'>
                <img src='hero.png' className='h-32' />
                <p className='txtgradient text-2xl font-bold'>Smart Contracts</p>
                <p className='mt-3 px-3 text-[#dad7d7]'>Smart contracts are self-executing contracts with the terms of the agreement directly written into code. They are built on blockchain platforms like Ethereum and are designed to automate and facilitate the execution of agreements without the need for intermediaries. Smart contracts work by enforcing the predefined rules and conditions of an agreement, ensuring that all parties involved fulfill their obligations. These contracts are stored and replicated across multiple nodes on a decentralized blockchain network, making them secure, transparent, and resistant to tampering.</p>
              </div>
            </div>
          </Carousel>
          </div>
        </div> */}

      <div className='flex flex-col mb-8 items-center bggradient2 relative mt-[530px] md:mt-36 p-4'>
      <div className='shadow-lg md:w-5/6 w-full p-4 bg-[#242526] rounded-lg'>
      <section className="bg-none">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              EMPOWERING CONNECTIONS, INNOVATION, AND GROWTH 
              </h2>
              <p className="mb-8 font-light lg:text-xl">
              Unlock seamless opportunities with <b>BLOCKOCEAN</b>, the ultimate hub for innovation and collaboration. Experience a user-friendly platform designed to empower connections, foster growth, and build thriving communities committed to turning your vision into reality.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    No Document Required
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Fully and truly decentralised platform
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Totally ownership free
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                Join us now and secure your financial future with our innovative
                platform.
              </p>
            </div>
            <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="feature_1.png"
              alt="dashboard feature image"
            />
          </div>

          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="feature_2.png"
              alt="feature image 2"
            />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                KEY ADVANTAGES OF OUR PLATFORM 
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                At <b>BLOCKOCEAN</b>, we strive to deliver exceptional opportunities, empowering users to achieve growth and long-term success. Our cutting-edge platform bridges the gap between visionaries and a network of supportive investors, driving impactful connections.
              </p>

              <ul
                role="list"
                className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    CORE token Will be used a fuel
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    100% instant disbursal of rewards & profits
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Ranks / Pools 
                  </span>
                </li>
                <li className="flex space-x-3">
                  <CorrectSvg />
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Huge passive income/profits
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-none transition-colors duration-1000">
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div className="col-span-2 mb-8">
            <p className="text-lg font-medium text-purple-600 dark:text-purple-500">
              Trusted Worldwide
            </p>
            <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl dark:text-white">
              Globally Trusted, Valued by Thousands -
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              At <b>BLOCKOCEAN</b>, we’re committed to dismantling barriers and
              fostering a more inclusive financial landscape. Join us to embark
              on the path to a brighter, financially independent future.
            </p>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <img src='matictoken.png' className='h-10' />
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                CORE Blockchain
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Empowering decentralized finance with efficiency and scalability
                on Core BlockChain.
              </p>
            </div>
            <div>
              <img src='matictoken.png' className='h-10' />
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                $CORE
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Efficient coin transactions facilitated seamlessly through
                CORE's infrastructure.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 256 256"
              >
                <g fill="none">
                  <rect width="256" height="256" fill="#65afff" rx="60" />
                  <path
                    fill="#000"
                    d="m165.536 25l-37.53 58.79H53L90.503 25z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.006 83.79h75.033L165.536 25H90.503z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m90.503 142.557l37.503-58.767L90.503 25L53 83.79z"
                    opacity="0.8"
                  />
                  <path
                    fill="#000"
                    d="m90.867 230.742l37.529-58.79h75.033l-37.53 58.79z"
                    opacity="0.45"
                  />
                  <path
                    fill="#000"
                    d="M128.396 171.952H53.363l37.503 58.79H165.9z"
                    opacity="0.6"
                  />
                  <path
                    fill="#000"
                    d="m165.899 113.185l-37.503 58.767l37.503 58.79l37.529-58.79z"
                    opacity="0.8"
                  />
                </g>
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                Smart Contract
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Automating agreements securely with transparent blockchain smart
                contracts.
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="#9b1c1c"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.636 5.636a9 9 0 1 0 12.728 12.728M5.636 5.636a9 9 0 1 1 12.728 12.728M5.636 5.636L12 12l6.364 6.364"
                />
              </svg>
              <h3 className="mb-2 mt-3 text-2xl font-bold dark:text-white">
                No Admin
              </h3>
              <p className="font-light text-gray-500 dark:text-gray-400">
                Decentralized governance ensures autonomy and transparency
                without administrators.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ROADMAP */}
      <div className="items-center bg-none p-4 md:p-8 gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            Our Vision
          </h2>
          <p className="mb-8 font-light lg:text-xl">
          Our vision is to revolutionize collaboration by fostering innovation, empowering individuals, and creating a globally connected community where opportunities flourish and success is accessible to all.
          </p>
          <p className="mb-8 font-light lg:text-xl">
          We aim to inspire growth, drive impactful connections, and build a sustainable ecosystem that transforms aspirations into achievements for a brighter future.
           </p>
        </div>
        <img
              className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src="hero.png"
              alt="dashboard feature image"
            />
      </div>

      <section className="bg-none">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p className="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">
                "Every journey begins with a single step, guided by dreams and fueled by determination. Embrace challenges as opportunities, and let your courage shape the path to success. The first step is always the most powerful."
              </p>
            </blockquote>
          </figure>
        </div>
      </section>

      </div>
      </div>

        {/* <div className='mt-16'>More</div> */}
     </div>
  )
}

export default Homepage