import { useContext, useState, useEffect, useRef } from "react";
import Header from "./Header"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Appstate} from '../App'
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from "antd";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Slots from "./Slots"; 
import Balance from "./Balance";
import Autopool from "./Autopool";
import { Link } from "react-router-dom";
import TelegramIcon from '@mui/icons-material/Telegram';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Limit from "./Limit";
import UpdateLevel from "./UpdateLevel";
import IncomeHistory from "./IncomeHistory";
import { useTimer } from 'react-timer-hook';
import moment from "moment";
import AutoPoolUpdate from "./AutoPoolUpdate";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Dashboard = () => {
  const useAppState = useContext(Appstate);
  const [time, setTime] = useState(0);
  const [user, setUser] = useState({
    start: 0,
    id: 0,
    referrer: "",
    slots: 0,
    selfUpgrade: 0,
    rank: 0,
    level: 0,
    b1Time: 0,
    lostIncome: 0,
    mainUpline: ""
  })
  const intervalRef = useRef(null);
  const [rank, setRank] = useState([0,0,0,0]);
  const [rankCount, setRankCount] = useState([0,0,0,0]);
  const [rankTimer, setRankTimer] = useState([0,0,0,0]);
  const [showTime, setShowTime] = useState(false);
  const [distTime, setDistTime] = useState(0);

  useEffect(() => { 
    async function getData() {
      setShowTime(false);
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _reward = await useAppState.contract.rewardInfo(useAppState.walletAddress);
      setUser({
        start: Number(_user.start),
        id: Number(_user.id),
        referrer: _user.referrer,
        slots: Number(_user.slots),
        selfUpgrade: useAppState.convert(_reward.selfUpgrade),
        rank: Number(_user.rank) > 0 ? `B${Number(_user.rank)}` : "None",
        level: Number(_user.rank),
        lostIncome: useAppState.convert(_reward.lostIncome),
        mainUpline: _user.mainUpline
      })
      let arr = [0,0,0,0];
      let _count = [0, 0, 0, 0];
      for(let i=0; i<4; i++) {
        let _rank = await useAppState.contract.rankPool(i);
        let _rnkCount = await useAppState.contract.getRankPoolUsersCount(i+1);
        arr[i] = Number(useAppState.convert(_rank));
        _count[i] = Number(_rnkCount)
      }

      setRankCount(_count);
      setRank(arr);
      
      let _rankTime = [0, 0, 0, 0];
      let _timer = await useAppState.contract.getRanksCountdown(useAppState.walletAddress);
      for(let i=0; i<4; i++) {
        _rankTime[i] = Number(_timer[i]) * 1000;
      }
      setRankTimer(_rankTime);

      let _distTime = await useAppState.contract.getPoolDistTime();
      setDistTime(Number(_distTime) * 1000)
      
      setShowTime(true);
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.start) > 0) {
        setTime(Date.now() - Number(user.start) * 1000);
      } else {
        setTime(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.start]);

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  return (
  <div className="flex justify-center p-4">
    <div className="md:w-4/5 w-full">
      <Header />
      <Balance />
      <UpdateLevel />
      <AutoPoolUpdate />
      
      <div className="mt-4 w-full flex flex-col md:flex-row items-center bg-[#8080821a] px-4 py-8 md:px-6 rounded-2xl shadow-lg profile">
        <div className="com h-[80px] md:h-[80px] ml-4 mt-4 mb-8 md:mb-0">
          <a className="th" target="_blank">
            <div className="relative bg-gray-900 bg-opacity-100 font-bold h-full w-full flex justify-center items-center profile">
              <img className="absolute top-0 rotate-180" src="pink-blur.png" />
              <span className="-rotate-45 text-xs">{user.id}</span>
          </div>
          </a>
        </div>
        <div className="ml-0 md:ml-12 mt-4 md:mt-0">
          <p className="mt-2 text-lime-400 font-semibold">Account : <span className="font-semibold text-white bg-gray-500 bg-opacity-20 rounded-2xl px-3">{useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(38)}</span></p>
          <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Member : </span>Since <span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{formatTime(time)}</span> by <span className="text-gray-300 bg-gray-500 bg-opacity-20 rounded-2xl px-3">{user.referrer.slice(0, 6) + "..." + user.referrer.slice(38)}</span></div>
          <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Slots : </span>{user.slots} Slots Purchased</div>
          <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Rank : </span>{user.rank}</div>
          {/* <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Auto Upgrade : </span>${user.selfUpgrade}</div> */}
          <div className="mt-2 font-semibold"><span className="text-lime-400 font-medium">Main Upline : </span>{user.mainUpline.toLowerCase() != useAppState.mainAddr.toLowerCase() ? <span>{user.mainUpline.slice(0,6)}...{user.mainUpline.slice(38)}</span> : "Default Refer"}</div>
          <p className="font-semibold break-all mt-2"><span className="text-lime-400 font-medium">Refer Link : </span><span className="bg-gray-500 text-gray-300 bg-opacity-20 rounded-2xl px-2">blockocean.ai/register?ref={user.id}</span>
            <CopyToClipboard text={`https://blockocean.ai/register?ref=${user.id}`} >
              <FileCopyIcon onClick={() => message.success("Copied")} fontSize="small" color="primary" className="ml-2 mr-2 cursor-pointer" />
            </CopyToClipboard>
          </p>
        </div>
      </div>

      {/* Slots */}
      <Slots />

      <div className='flex justify-between'>
        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
          <div className='font-bold'>
            <p className=''>Self Upgrade</p>
            <p className='text-xl'>${user.selfUpgrade}</p>
          </div>
          <img src='passup.png' className='h-16' />
        </div>

        <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 rounded-2xl shadow-lg profile">
          <div className='font-bold'>
            <p className=''>Lost Income</p>
            <p className='text-xl'>${user.lostIncome}</p>
          </div>
          <img src='strongleg.png' className='h-20' />
        </div>
      </div>

      {user.level > 0 && user.level < 4 ?
        <div className='bg-[#8080821a] p-3 mt-4 rounded-lg flex justify-between items-center'>
          <div>
              <img src={`b${user.level}.png`} className='h-20' />
          </div>
          <div className='flex flex-col items-center'>
              <p className='text-lg font-semibold animate-pulse text-lime-500'>B{user.level} Reward Countdown</p>
              <p className='text-xl text-red-500 font-bold'>{showTime ? <MyTimer expiryTimestamp={rankTimer[user.level - 1]} /> : null}</p>
          </div>
        </div>
      : null}

      <div className="mt-4 w-full flex flex-col justify-center items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg autopool">
        <p className="text-lime-500 font-bold">Rank Pool Countdown</p>
        <p className='mt-2 text-xl text-white font-bold'>{showTime ? <MyTimer expiryTimestamp={distTime} /> : null}</p>
      </div>

      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
          <span className='flex items-center font-bold'>
            <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
              <LeaderboardIcon />
            </span> 
            B1
          </span>
          <span className="font-bold">{rankCount[0]}</span>
      </div>
      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
          <span className='flex items-center font-bold'>
            <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
              <LeaderboardIcon />
            </span> 
            B2
          </span>
          <span className="font-bold">{rankCount[1]}</span>
      </div>
      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
          <span className='flex items-center font-bold'>
            <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
              <LeaderboardIcon />
            </span> 
            B3
          </span>
          <span className="font-bold">{rankCount[2]}</span>
      </div>
      <div className="mt-4 w-full flex justify-between items-center bg-[#8080821a] px-2 py-2 rounded-lg shadow-lg wallet">
          <span className='flex items-center font-bold'>
            <span className="font-semibold mr-2 text-white bg-gray-500 bg-opacity-20 rounded-2xl flex justify-center items-center px-2 py-1">
              <LeaderboardIcon />
            </span> 
            B4 
          </span>
          <span className="font-bold">{rankCount[3]}</span>
      </div>

      {/* Autopool */}
      <Autopool />

      <IncomeHistory />
    </div>
  </div>
  )
}

export default Dashboard