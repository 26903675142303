import React, { useContext, useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook';
import { Appstate } from '../App';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}


const Limit = () => {
    const useAppState = useContext(Appstate);
    const [time, setTime] = useState(0);
    const [showTime, setShowTime] = useState(false);
    const [rewardLimit, setRewardLimit] = useState(0);

    useEffect(() => {
        async function getData() {
            setShowTime(false);
            let _time = await useAppState.contract.getActivationTimer(useAppState.walletAddress);
            setTime(Number(_time[1]) * 1000);
            setShowTime(true);

            let _limit = await useAppState.contract.getUserLimit(useAppState.walletAddress);
            setRewardLimit(useAppState.convert(_limit));
        }
        getData();
    },[useAppState.change, useAppState.walletAddress])

  return (
    <div className='bg-[#8080821a] p-3 mt-3 rounded-lg flex justify-between'>
        <div>
            <p className='text-lime-500 font-semibold'>Activation Time</p>
            <p className='text-lg text-red-500 font-bold'>{showTime ? <MyTimer expiryTimestamp={time} /> : null}</p>
        </div>
        <div>
            <p className='text-lime-500 font-semibold'>Reward Limit</p>
            <p className='text-lg font-bold'>${rewardLimit}</p>
        </div>
    </div>
  )
}

export default Limit