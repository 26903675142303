import React, { useContext, useEffect, useState } from 'react'
import { Appstate } from '../App'
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';

const AutoPoolUpdate = () => {
    const useAppState = useContext(Appstate);
    const [isAvl, setIsAvl] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slot, setSlot] = useState(0);
    
    useEffect(() => {
        async function getData() {
            for(let i=0; i<7; i++) {
                let _isAvl = await useAppState.contract.isAutopoolUpdate(useAppState.walletAddress, i+1);
                if(_isAvl) {
                    setIsAvl(_isAvl);
                    setSlot(i+1);
                    break;
                }
            }
        }
        getData();
    },[useAppState.change, useAppState.walletAddress])

    const update = async () => {
        setLoading(true);
        try {
            let tx = await useAppState.contract.updateAutopool(useAppState.walletAddress, slot);
            await tx.wait();
            message.success("Sucessfully Updated");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason)
        }
        setLoading(false);
    }

  return (
    <>
    {isAvl && slot > 0 ? 
        <div className='bg-[#8080821a] p-3 mt-3 rounded-lg flex justify-between items-center'>
            <div>
                <p className='text-xs animate-pulse text-lime-500'>Update Available</p>
                <h1 className='font-bold'>Slot - {slot} (O4)</h1>
            </div>
            <div className='flex flex-col items-center'>
                <button onClick={update} className='bg-lime-500 mb-2 w-[120px] mt-4 flex justify-center font-semibold p-2 rounded-lg'>{loading ? <TailSpin height={22} color='white' /> : "Update"}</button>
            </div>
        </div>
    : null}
    </>
  )
}

export default AutoPoolUpdate