import React, { useContext, useEffect, useState } from 'react'
import { Appstate } from '../App';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PixIcon from '@mui/icons-material/Pix';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const Incomes = () => {
    const useAppState = useContext(Appstate);
    const [refIncome, setRefIncome] = useState(0);
    const [rankIncome, setRankIncome] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [gpIncome, setGpIncome] = useState(0);

    useEffect(() => {
        async function getData() {
            let _cur = await useAppState.contract.rewardInfo(useAppState.walletAddress);
            let _ref = Number(useAppState.convert(_cur.refIncome)) 
            let _rank = Number(useAppState.convert(_cur.rankIncome)) + Number(useAppState.convert(_cur.rankTaken))
            setRefIncome(_ref);
            setRankIncome(_rank);
            let _rev = Number(useAppState.convert(_cur.totalRevenue));
            setRevenue(_rev);
            setGpIncome(_rev - (_ref + _rank));
        }
        getData();
    },[useAppState.walletAddress, useAppState.change])

    return (
      <div className=''>
        <div className='flex justify-between'>
        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 md:py-4 rounded-2xl shadow-lg wallet">
          <div className='font-semibold'>
            <p className=''>Total Income</p>
            <p className='text-xl'>${revenue.toFixed(2)}</p>
          </div>
          <div className='text-white opacity-80 flex justify-center items-center md:text-[46px] text-[38px]'><PriceChangeIcon fontSize='inherit' color='inherit' /></div>
        </div>

        <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 md:py-4 rounded-2xl shadow-lg wallet">
          <div className='font-semibold'>
            <p className=''>Global Pool Income</p>
            <p className='text-xl'>${gpIncome.toFixed(2)}</p>
          </div>
          <div className='text-white opacity-80 flex justify-center items-center md:text-[46px] text-[38px]'><PixIcon fontSize='inherit' color='inherit' /></div>
        </div>
      </div>

      <div className='flex justify-between'>
        <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 md:py-4 rounded-2xl shadow-lg wallet">
          <div className='font-semibold'>
            <p className=''>Refer Income</p>
            <p className='text-xl'>${refIncome.toFixed(2)}</p>
          </div>
          <div className='text-white opacity-80 flex justify-center items-center md:text-[46px] text-[38px]'><GroupAddIcon fontSize='inherit' color='inherit' /></div>
        </div>

        <div className="ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-4 py-2 md:py-4 rounded-2xl shadow-lg wallet">
          <div className='font-semibold'>
            <p className=''>Rank Pool Income</p>
            <p className='text-xl'>${rankIncome.toFixed(2)}</p>
          </div>
          <div className='text-white opacity-80 flex justify-center items-center md:text-[46px] text-[38px]'><LocalPoliceIcon fontSize='inherit' color='inherit' /></div>
        </div>
      </div>
      </div>
    )
}

export default Incomes