import React, { useContext, useEffect, useState } from 'react'
import { Appstate } from '../App';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import Filter7Icon from '@mui/icons-material/Filter7';
import { Filter8Outlined, Filter9Outlined } from '@mui/icons-material';

const Autopool = () => {
    const useAppState = useContext(Appstate);
    const [slots, setSlots] = useState(0);
    const [income, setIncome] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [income2, setIncome2] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [team, setTeam] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [team2, setTeam2] = useState([0,0,0,0,0,0,0,0,0,0]);
    const [show, setShow] = useState([false, false, false, false, false, false, false, false, false, false])
    const [show2, setShow2] = useState([false, false, false, false, false, false, false, false, false, false])

    useEffect(() => {
        async function getData() {
            let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
            setSlots(Number(_user.slots));
            // let _show1 = [true, true, true, true, true, true, true, true, true, true]
            // let _show2 = [true, true, true, true, true, true, true, true, true, true]
            let _show1 = [false, false, false, false, false, false, false, false, false, false]
            let _show2 = [false, false, false, false, false, false, false, false, false, false]
            let _getShow = await useAppState.contract.getGPActive(useAppState.walletAddress);
            for(let i=0; i<10; i++) {
              _show1[i] = _getShow[0][i];
              _show2[i] = _getShow[1][i];
            }
            setShow(_show1);
            setShow2(_show2);
            let _income = await useAppState.contract.getGPIncome(useAppState.walletAddress);
            let _arr = [0,0,0,0,0,0,0,0,0,0];
            let _arr2 = [0,0,0,0,0,0,0,0,0,0];
            for(let i=0; i<10; i++) {
                _arr[i] = Number(useAppState.convert(_income[0][i]))
                _arr2[i] =  Number(useAppState.convert(_income[1][i]))
            }
            setIncome(_arr);
            setIncome2(_arr2);
            let _team = await useAppState.contract.getGPTeam(useAppState.walletAddress);
            console.log(_team);
            let _team1 = [0,0,0,0,0,0,0,0,0,0];
            let _team2 = [0,0,0,0,0,0,0,0,0,0];
            for(let i=0; i<10; i++) {
                _team1[i] = Number(_team[0][i]);
                _team2[i] = Number(_team[1][i]);
            }
            setTeam(_team1);
            setTeam2(_team2);
        }
        getData();
    },[useAppState.walletAddress, useAppState.change])

  return (
    <div className=''>
        <div className='flex flex-col md:flex-row justify-between'>
          {show[0] ?
          <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[0]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><LooksOneIcon /> O4</p>
                <p className='text-xl'>${income[0]}</p>
              </div>
            </div>

            {show2[0] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[0]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><LooksOneIcon /> O3</p>
                <p className='text-xl'>${income2[0]}</p>
              </div>
            </div>: <div></div>}
          </>
          :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[1] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[1]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><LooksTwoIcon /> O4</p>
                <p className='text-xl'>${income[1]}</p>
              </div>
            </div>

            {show2[1] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[1]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><LooksTwoIcon /> O3</p>
                <p className='text-xl'>${income2[1]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[2] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[2]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks3Icon /> O4</p>
                <p className='text-xl'>${income[2]}</p>
              </div>
            </div>

            {show2[2] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[2]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks3Icon /> O3</p>
                <p className='text-xl'>${income2[2]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[3] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[3]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks4Icon /> O4</p>
                <p className='text-xl'>${income[3]}</p>
              </div>
            </div>

            {show2[3] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[3]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks4Icon /> O3</p>
                <p className='text-xl'>${income2[3]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[4] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[4]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks5Icon /> O4</p>
                <p className='text-xl'>${income[4]}</p>
              </div>
            </div>

            {show2[4] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[4]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks5Icon /> O3</p>
                <p className='text-xl'>${income2[4]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[5] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[5]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks6Icon /> O4</p>
                <p className='text-xl'>${income[5]}</p>
              </div>
            </div>

            {show2[5] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[5]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Looks6Icon /> O3</p>
                <p className='text-xl'>${income2[5]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[6] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[6]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter7Icon /> O4</p>
                <p className='text-xl'>${income[6]}</p>
              </div>
            </div>

            {show2[6] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[6]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter7Icon /> O3</p>
                <p className='text-xl'>${income2[6]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[7] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[7]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter8Outlined /> O4</p>
                <p className='text-xl'>${income[7]}</p>
              </div>
            </div>

            {show2[7] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[7]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter8Outlined /> O3</p>
                <p className='text-xl'>${income2[7]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[8] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[8]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter9Outlined /> O4</p>
                <p className='text-xl'>${income[8]}</p>
              </div>
            </div>

            {show2[8] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[8]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'><Filter9Outlined /> O3</p>
                <p className='text-xl'>${income2[8]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          {show[9] ? 
            <>
            <div className="mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team[9]}_4x2.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'>10 - O4</p>
                <p className='text-xl'>${income[9]}</p>
              </div>
            </div>

            {show2[9] ? <div className="ml-0 md:ml-4 mt-6 w-full flex justify-between items-center bg-[#8080821a] px-2 md:px-4 py-2 rounded-2xl shadow-lg wallet">
              <img src={`${team2[9]}_3x3.png`} className='h-24' />
              <div className='font-bold bg-[#8080821a] px-3 py-3 rounded-lg shadow-lg wallet'>
                <p className='text-md'>10 - O3</p>
                <p className='text-xl'>${income2[9]}</p>
              </div>
            </div>: <div></div>}
            </>
            :null}
        </div>

    </div>
  )
}

export default Autopool