import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import {Appstate} from '../App'

const IncomeHistory = () => {
    const useAppState = useContext(Appstate)
    const [income, setIncome] = useState([{
        sno: 0,
        incomeType: 0,
        amount: 0,
        time: 0,
        slot: 0,
        isLost: false
    }])
    const [incType, setIncType] = useState(["Refer", "Layer", "4x2 L1", "4x2 L2", "3x3 L1", "3x3 L2", "3x3 L3", "Rank", "Auto Upgrade"]);

    useEffect(() => {
        async function getData() {
            setIncome([]);
            let _income = await useAppState.contract.getIncomeHistory(useAppState.walletAddress);
            let _newIncomeArr = [];
            for(let i=0; i<_income.length; i++) {
                _newIncomeArr.push({
                    sno: i+1,
                    incomeType: Number(_income[i].incomeType),
                    amount: useAppState.convert(_income[i].amount),
                    time: Number(_income[i].time) * 1000,
                    slot: Number(_income[i].slot),
                    isLost: _income[i].isLost
                })
            }
            setIncome(_newIncomeArr.reverse());
        }
        getData();
    },[useAppState.change, useAppState.walletAddress])


  return (
    <div className=''>
        {income.length > 0 ? (
          <div className="flex justify-center md:p-0 mt-8">
            <div className="w-full">
            <h1 className="font-bold text-xl px-2 font-mono w-full flex justify-center py-1 border-b-2 border-red-500 bg-gray-800/40 rounded-full ">
                Recent <span className="text-green-500 ml-2">Income</span>
              </h1>
              <div className="overflow-auto h-52 flex justify-between w-full mt-2 p-2 rounded-sm">
                <div className="whitespace-nowrap ml-0 md:ml-0">
                  <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    SNo.
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-white bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.sno}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Income 
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className={`${e.isLost ? "text-red-500" : "text-white"} text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4`}
                      >
                        {incType[e.incomeType]} {e.isLost ? <span className='text-xs'>(Lost)</span> : null}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Amount
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-white bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.amount} <span className="text-[#FFE900]">USDT</span> {e.isLost ? <span className='text-xs text-red-500'>(Lost)</span> : null}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Slot/Rank
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-white bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.incomeType == 7 ? `B${e.slot}` : `Slot ${e.slot}`}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(113,205,99,0.16)] bg-[rgba(128,203,112,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Time
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,124,240,0.16)] text-white bg-[rgba(89,117,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {useAppState.getUTCTime(e.time)}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
    </div>
  )
}

export default IncomeHistory